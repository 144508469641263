<!--
// nuxt-ui/components/layout/header/DropbarCompetitionMenuMobile.vue
-->
<script setup lang="ts">
import SocialIcons from "~/nuxt-ui/components/Shared/SocialIcons.vue";
import ContextOtherLeaguesLogo from "~/nuxt-ui/components/context-shared/ContextOtherLeaguesLogo.vue";
import DropDownIcon from "~/nuxt-ui/components/Shared/icons/DropDownIcon.vue";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";

const { locale, locales } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const router = useRouter();
const { isKings, webContext, isQueens } = useWebContext();
const { region } = useCompetitionStore();
const { isSpain, isAmericas, isWorldCup, isItaly } = useRegions();

const availableLocales = computed(() => {
  const _locales = locales.value;
  return !!_locales && _locales.filter(i => i.code !== locale.value);
});

const localePath = useLocalePath();

type Props = {
  showNavCompetitionMobile: boolean;
};

withDefaults(defineProps<Props>(), {
  showNavCompetitionMobile: false,
});

const emit = defineEmits(["update:showNavCompetitionMobile"]);

const languagesContainerMobile = ref(false);
const playersContainerMobile = ref(false);
const competitionContainerMobile = ref(false);

const toggleLanguagesContainerMobile = () => {
  languagesContainerMobile.value = !languagesContainerMobile.value;
};

const togglePlayersContainerMobile = () => {
  playersContainerMobile.value = !playersContainerMobile.value;
};

const toggleCompetitionContainerMobile = () => {
  competitionContainerMobile.value = !competitionContainerMobile.value;
};

const socialProfilesMeta = region?.social[webContext];

const socialProfiles = socialProfilesMeta
  ? {
      twitter: socialProfilesMeta.find(s => s.name === "twitter")?.url,
      discord: socialProfilesMeta.find(s => s.name === "discord")?.url,
      instagram: socialProfilesMeta.find(s => s.name === "instagram")?.url,
      tiktok: socialProfilesMeta.find(s => s.name === "tiktok")?.url,
      youtube: socialProfilesMeta.find(s => s.name === "youtube")?.url,
    }
  : {};

const hideCompetitionDropbar = () => {
  emit("update:showNavCompetitionMobile", false);
  playersContainerMobile.value = false;
  competitionContainerMobile.value = false;
  const theBody: HTMLBodyElement | null = document.querySelector("body");
  if (theBody) theBody.classList.remove("overflow-hidden");
};
</script>

<template>
  <div
    :class="[
      'dropbar-competition-nav-mobile',
      !showNavCompetitionMobile ? 'opacity-0 -top-96' : 'opacity-100 top-[72px]',
    ]"
  >
    <nav class="bg-gray-800" id="NavCompetitionMobile">
      <!--        Teams-->
      <ul v-if="(!isItaly && isKings) || (isQueens && !isAmericas)">
        <li class="nav-competition-mobile-item">
          <nuxt-link
            :to="localePath('region-Teams')"
            class="nav-competition-mobile-item-link"
            @click="hideCompetitionDropbar"
          >
            {{ $t("header.navCompetition.teams") }}
          </nuxt-link>
        </li>
        <!--        DROPDOWN Players-->
        <li class="nav-competition-mobile-item">
          <button
            class="flex justify-between px-4 text-sm items-center min-w-full min-h-full"
            @click="togglePlayersContainerMobile"
          >
            {{ $t("header.navCompetition.players") }}
            <span class="text-gray-400">
              <drop-down-icon width="12" height="12" />
            </span>
          </button>
        </li>
        <li
          :class="playersContainerMobile ? 'h-auto' : 'h-0'"
          class="transition-[height] duration-300 ease-in-out overflow-hidden bg-gray-900"
        >
          <ul class="text-sm font-normal text-gray-300">
            <li class="hover:text-white h-12 flex justify-start items-center relative">
              <nuxt-link
                :to="localePath('region-PlayersDraft')"
                no-prefetch
                class="nav-competition-mobile-item-link"
                @click="hideCompetitionDropbar"
              >
                {{ $t("header.navCompetition.playersDraft") }}
              </nuxt-link>
            </li>
            <li class="hover:text-white h-12 flex justify-start items-center relative">
              <nuxt-link
                :to="localePath('region-PlayersVariable')"
                no-prefetch
                class="nav-competition-mobile-item-link"
                @click="hideCompetitionDropbar"
              >
                {{ $t("header.navCompetition.12-13") }}
              </nuxt-link>
            </li>
            <li
              class="hover:text-white h-12 flex justify-start items-center relative"
              v-if="isKings && isSpain"
            >
              <nuxt-link
                :to="localePath('region-PlayersLegend')"
                no-prefetch
                class="nav-competition-mobile-item-link"
                @click="hideCompetitionDropbar"
              >
                {{ $t("header.navCompetition.legends") }}
              </nuxt-link>
            </li>
          </ul>
        </li>
        <!--        DROPDOWN Competition-->
        <li class="nav-competition-mobile-item">
          <button
            class="flex justify-between px-4 text-sm items-center min-w-full min-h-full"
            @click="toggleCompetitionContainerMobile"
          >
            {{ $t("header.navCompetition.competition") }}
            <span class="text-gray-400">
              <drop-down-icon width="12" height="12" />
            </span>
          </button>
        </li>
        <li
          :class="competitionContainerMobile ? 'h-auto' : 'h-0'"
          class="transition-[height] duration-300 ease-in-out overflow-hidden bg-gray-900"
        >
          <ul class="text-sm font-normal text-gray-300">
            <li class="hover:text-white h-12 flex justify-start items-center relative">
              <nuxt-link
                :to="localePath('region-Matches')"
                class="nav-competition-mobile-item-link"
                @click="hideCompetitionDropbar"
              >
                {{ $t("header.navCompetition.matches") }}
              </nuxt-link>
            </li>
            <li class="hover:text-white h-12 flex justify-start items-center relative">
              <nuxt-link
                :to="localePath('region-Tables')"
                class="nav-competition-mobile-item-link"
                @click="hideCompetitionDropbar"
              >
                {{ $t("header.navCompetition.tables") }}
              </nuxt-link>
            </li>
            <li class="hover:text-white h-12 flex justify-start items-center relative">
              <nuxt-link
                :to="localePath('region-Stats')"
                class="nav-competition-mobile-item-link"
                @click="hideCompetitionDropbar"
                no-prefetch
              >
                {{ $t("header.navCompetition.stats") }}
              </nuxt-link>
            </li>
          </ul>
        </li>

        <li class="nav-competition-mobile-item" v-if="isKings && isWorldCup">
          <nuxt-link
            :to="localePath('region-Tournament')"
            no-prefetch
            class="nav-competition-mobile-item-link"
            @click="hideCompetitionDropbar"
            >{{ $t("header.navCompetition.tournament") }}</nuxt-link
          >
        </li>
        <li v-if="isSpain || isAmericas" class="nav-competition-mobile-item">
          <nuxt-link
            :to="localePath('region-Draft')"
            no-prefetch
            class="nav-competition-mobile-item-link"
            @click="hideCompetitionDropbar"
          >
            {{ $t("header.navCompetition.draft") }}
          </nuxt-link>
        </li>
        <!--        Fantasy-->
        <li class="nav-competition-mobile-item" v-if="isKings && !isAmericas && !isWorldCup">
          <nuxt-link
            to="https://kingsleague.hro.gg/"
            no-prefetch
            external
            target="_blank"
            class="nav-competition-mobile-item-link"
            @click="hideCompetitionDropbar"
          >
            <span class="flex items-center gap-1">
              <!--              F icon-->
              <svg
                width="auto"
                height="12"
                viewBox="0 0 345 341"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M122.9 226.4H213.7L262.5 141.9H122.9V84.5H295.6L344.4 0H0.799805V302.5L115.3 340.1L122.9 337.6V226.4Z"
                  fill="currentColor"
                />
              </svg>
              {{ $t("header.navCompetition.fantasy") }}
            </span>
          </nuxt-link>
        </li>
      </ul>

      <ul v-else-if="isItaly || (isQueens && isAmericas)">
        <li class="nav-competition-mobile-item">
          <nuxt-link
            :to="localePath('region')"
            no-prefetch
            class="nav-competition-mobile-item-link"
            @click="hideCompetitionDropbar"
          >
            {{ $t("header.navCompetition.draft") }}
          </nuxt-link>
        </li>
      </ul>
    </nav>
    <!--      locales-->
    <div class="bg-gray-900 border-b border-b-gray-700">
      <button class="nav-mobile-language-item" @click="toggleLanguagesContainerMobile">
        {{ $t("header.dropbarCompetition.language") }}
        <span class="font-semibold text-sm py-1 text-center flex items-center text-gray-500">
          {{ locale }}
          <drop-down-icon class="ml-2" width="12" height="12" />
        </span>
      </button>
      <div
        :class="languagesContainerMobile ? 'h-auto' : 'h-0'"
        class="transition-[height] duration-300 ease-in-out overflow-hidden"
      >
        <ul class="text-sm font-normal text-gray-300">
          <li v-for="avLocale in availableLocales" :key="avLocale.code">
            <a :href="switchLocalePath(avLocale.code)" class="block p-2 px-4 hover:text-white">
              {{ avLocale.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="nav-mobile-additional-context-info-item">
      <social-icons :social-profiles="socialProfiles" height="17"></social-icons>
      <context-other-leagues-logo></context-other-leagues-logo>
    </div>
  </div>
</template>

<style scoped>
.dropbar-competition-nav-mobile {
  @apply text-white z-10 text-sm font-medium w-screen absolute left-0 right-0 transition-all duration-300 lg:hidden;
}

.nav-competition-mobile-item {
  @apply h-12 border-b border-b-gray-700 relative hover:bg-black;
}

.nav-competition-mobile-item-link {
  @apply absolute top-0 left-0 right-0 bottom-0 flex items-center justify-start px-4;
}

.nav-mobile-language-item {
  @apply text-gray-400 flex justify-between py-1 px-4 text-sm items-center min-w-full;
}

.nav-mobile-additional-context-info-item {
  @apply bg-gray-900 text-white flex justify-between px-4 text-sm items-center border-b border-b-gray-700 py-3;
}
</style>
