<!--
// nuxt-ui/components/layout/Toolbar.vue
-->
<script setup lang="ts">
import SocialIcons from "~/nuxt-ui/components/Shared/SocialIcons.vue";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";

type Props = {
  isVisible: boolean;
};

withDefaults(defineProps<Props>(), {
  isVisible: true,
});

const { region } = useCompetitionStore();
const { webContext } = useWebContext();

const socialProfiles = {
  twitter: region?.social[webContext]?.find(s => s.name === "twitter")?.url,
  discord: region?.social[webContext]?.find(s => s.name === "discord")?.url,
  instagram: region?.social[webContext]?.find(s => s.name === "instagram")?.url,
  tiktok: region?.social[webContext]?.find(s => s.name === "tiktok")?.url,
  youtube: region?.social[webContext]?.find(s => s.name === "youtube")?.url,
};
</script>

<template>
  <div
    id="toolbar"
    :class="[
      'bg-black justify-end pl-[152px] pr-6 text-white py-2 hidden min-h-[29px]',
      { 'lg:flex': isVisible },
    ]"
  >
    <social-icons :social-profiles="socialProfiles" width="13" height="13"></social-icons>
  </div>
</template>

<style scoped></style>
