// nuxt-ui/Shared/composables/twitch.ts

import { useRoute } from "#app";
import type { TwitchLive } from "~/src/twitch/domain/models/TwitchLive";
import type { TwitchLiveDTO } from "~/src/twitch/infrastructure/DTO/TwitchLiveDTO";

interface UseTwitch {
  getTwitchLiveInfo: () => Promise<TwitchLive | void>;
}

export const useTwitch = (): UseTwitch => {
  return {
    getTwitchLiveInfo,
  };

  async function getTwitchLiveInfo(): Promise<TwitchLive | void> {
    try {
      const { params } = useRoute();
      const region = params.region;
      //todo - create world-cup at backend
      const path =
        region === "world-cup" ? "/api/prd/twitch-users/espana" : `/api/prd/twitch-users/${region}`;

      const response = await $fetch<TwitchLiveDTO>(path);

      if (response) {
        return response;
      } else {
        console.error("NOTE: Fetched Twitch live info is null or undefined");
      }
    } catch (e) {
      console.error(e);
    }
  }
};
