<!--
// nuxt-ui/components/layout/header/DropbarRegionsMenuMobile.vue
-->
<script setup lang="ts">
import type { Region } from "~/src/region/domain/models/Region";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import regionUiService from "~/src/region/infrastructure/ui-services/regionUiService";

const localePath = useLocalePath();
const { params } = useRoute();
const { webContext } = useWebContext();
const { getWebContextRegions } = regionUiService();

const regions = await getWebContextRegions();

type Props = {
  showNavRegionsMobile: boolean;
};

withDefaults(defineProps<Props>(), {
  showNavRegionsMobile: false,
});

const emit = defineEmits(["regionSelected"]);

const hideRegionsDropbar = () => {
  emit("regionSelected");
  const theBody: HTMLBodyElement | null = document.querySelector("body");
  if (theBody) theBody.classList.add("overflow-hidden");
};

const activeClass = (region: Region): boolean => {
  return params.region === region.slug;
};
</script>

<template>
  <div
    id="dropbar-regions"
    :class="[
      'dropbar-regions-nav-mobile',
      !showNavRegionsMobile ? 'opacity-0 -top-96' : 'opacity-100 top-[72px]',
    ]"
  >
    <nav class="bg-white text-black">
      <ul>
        <li v-for="region in regions" :key="region.id" class="nav-region-mobile-item">
          <a
            :href="localePath({ name: 'region', params: { region: region.slug } })"
            aria-current="page"
            :class="[{ 'active-region-mobile': activeClass(region) }, 'nav-region-mobile-item-link']"
            @click="hideRegionsDropbar"
            >{{ region.name }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<style scoped>
.dropbar-regions-nav-mobile {
  @apply z-10 text-sm font-medium w-screen absolute left-0 right-0 transition-all duration-300 lg:hidden;
}

.nav-region-mobile-item {
  @apply h-12 border-b border-b-neutral-600 relative;
}

.nav-region-mobile-item-link {
  @apply absolute top-0 left-0 right-0 bottom-0 flex items-center justify-start pl-4;
}
</style>
