<!--
// nuxt-ui/components/layout/NavRegions.vue
-->
<script setup lang="ts">
import type { Region } from "~/src/region/domain/models/Region";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import regionUiService from "~/src/region/infrastructure/ui-services/regionUiService";

const localePath = useLocalePath();
const { params } = useRoute();
const { webContext } = useWebContext();
const { getWebContextRegions } = regionUiService();

const regions = await getWebContextRegions();

const activeClass = (region: Region): boolean => {
  return params.region === region.slug;
};
</script>

<template>
  <nav>
    <div class="nav-regions" id="navbar-regions">
      <ul class="nav-regions-links-wrapper">
        <li v-for="region in regions" :key="region.id" class="nav-regions-item">
          <a
            :href="localePath({ name: 'region', params: { region: region.slug } })"
            aria-current="page"
            :class="{ 'active-region': activeClass(region) }"
            >{{ region.name }}</a
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<style scoped>
.nav-regions {
  @apply hidden w-full h-14 flex-col justify-center lg:flex lg:w-auto;
}

.nav-regions-links-wrapper {
  @apply flex font-medium text-sm text-black space-x-5 mt-0 border-0 h-full;
}

.nav-regions-item {
  @apply flex flex-col justify-center;
}
</style>
