<!--
// nuxt-ui/components/context-shared/ContextLogoLeagueFooter.vue
-->
<script setup lang="ts">
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";

const { isSpain, isAmericas, isWorldCup, isItaly } = useRegions();
const { isKings, isQueens } = useWebContext();

const images = {
  kingsSpain: {
    src: "/img/league-logos/Footer-Kings-League-Infojobs.svg",
    alt: "Kings League Infojobs",
  },
  kingsAmericas: {
    src: "/img/league-logos/Footer-Kings-League-Santander.svg",
    alt: "Kings League Santander",
  },
  kingsItaly: {
    src: "/img/league-logos/Footer-kings-league-italia.svg",
    alt: "Kings League Italia",
  },
  kingsWorldCup: {
    src: "/img/league-logos/Footer-Kings-World-Cup.svg",
    alt: "Kings World Cup",
  },
  queensSpain: {
    src: "/img/league-logos/Footer-Queens-League-Oysho.svg",
    alt: "Queens League Oysho",
  },
  queensAmericas: {
    src: "/img/league-logos/Footer-Queens-League-Oysho-Americas.png",
    alt: "Queens League Oysho Américas",
  },
};
</script>

<template>
  <NuxtImg
    v-if="isKings && isSpain"
    :src="images.kingsSpain.src"
    :alt="images.kingsSpain.alt"
    class="logo"
    loading="lazy"
    width="312"
    height="68"
    sizes="189px md:312px"
  />
  <NuxtImg
    v-if="isKings && isAmericas"
    :src="images.kingsAmericas.src"
    :alt="images.kingsAmericas.alt"
    class="logo"
    loading="lazy"
    width="312"
    height="80"
    sizes="189px md:312px"
  />
  <NuxtImg
    v-if="isKings && isItaly"
    :src="images.kingsItaly.src"
    :alt="images.kingsItaly.alt"
    class="logo"
    loading="lazy"
    width="312"
    height="66"
    sizes="189px md:312px"
  />

  <NuxtImg
    v-if="isKings && isWorldCup"
    :src="images.kingsWorldCup.src"
    :alt="images.kingsWorldCup.alt"
    class="logo-kwc"
    loading="lazy"
    width="220"
    height="86"
    sizes="160px md:220px"
  />

  <NuxtImg
    v-if="isQueens && isSpain"
    :src="images.queensSpain.src"
    :alt="images.queensSpain.alt"
    class="logo"
    loading="lazy"
    width="866"
    height="165"
    sizes="189px md:312"
  />

  <NuxtImg
    v-if="isQueens && isAmericas"
    :src="images.queensAmericas.src"
    :alt="images.queensAmericas.alt"
    class="logo"
    loading="lazy"
    width="866"
    height="206"
    sizes="189px md:312"
  />
</template>

<style scoped>
.logo {
  @apply w-[189px] md:w-[312px] h-auto;
}

.logo-kwc {
  @apply w-[160px] md:w-[220px] h-auto;
}
</style>
