<!--
// nuxt-ui/components/layout/NavCompetition.vue
-->
<script setup lang="ts">
import type { SocialMedia } from "~/src/region/domain/models/Region";
import ButtonsNavbar from "~/nuxt-ui/components/layout/header/ButtonsNavbar.vue";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import DropDownIcon from "~/nuxt-ui/components/Shared/icons/DropDownIcon.vue";

type Props = { twitchIsLive: boolean };

defineProps<Props>();

const localePath = useLocalePath();
const { isKings, webContext, isQueens } = useWebContext();
const { region } = useCompetitionStore();
const { isSpain, isAmericas, isWorldCup, isItaly } = useRegions();
const { locale } = useI18n();

const socialMedia: SocialMedia[] = region?.social[webContext] || [];
const liveButtonUrl: string | undefined =
  isWorldCup && locale.value === "es"
    ? "https://www.twitch.tv/kingsleague"
    : socialMedia.find(s => s.name === "twitch")?.url;
</script>

<template>
  <div class="hidden lg:flex w-full justify-between items-center">
    <nav id="navbar-competition">
      <ul
        v-if="(!isItaly && isKings) || (isQueens && !isAmericas)"
        class="flex lg:space-x-6 text-white text-sm uppercase"
      >
        <!--        TEAMS-->
        <li class="nav-link">
          <nuxt-link :to="localePath('region-Teams')">{{ $t("header.navCompetition.teams") }}</nuxt-link>
        </li>

        <!--PLAYERS Dropdown-->
        <li class="nav-link">
          <button
            id="playersNavbar"
            data-dropdown-toggle="dropdownPlayersNavbar"
            data-dropdown-trigger="hover"
            class="nav-link inline-flex items-center uppercase"
            type="button"
          >
            {{ $t("header.navCompetition.players") }}
            <drop-down-icon class="ml-1" width="8" height="8" />
          </button>
          <!-- Dropdown menu -->
          <div
            id="dropdownPlayersNavbar"
            class="z-30 hidden rounded-lg shadow text-left bg-gray-900 border border-gray-700 py-2 w-[262px]"
          >
            <ul class="text-sm text-gray-400" aria-labelledby="playersNavbar">
              <li class="subitem-dropdown-players">
                <nuxt-link no-prefetch :to="localePath('region-PlayersDraft')" class="subitem-link-navbar">
                  {{ $t("header.navCompetition.playersDraft") }}
                </nuxt-link>
              </li>
              <li class="subitem-dropdown-players">
                <nuxt-link no-prefetch :to="localePath('region-PlayersVariable')" class="subitem-link-navbar">
                  {{ $t("header.navCompetition.12-13") }}
                </nuxt-link>
              </li>
              <li class="subitem-dropdown-players" v-if="isKings && !isAmericas && !isWorldCup">
                <nuxt-link no-prefetch :to="localePath('region-PlayersLegend')" class="subitem-link-navbar">
                  {{ $t("header.navCompetition.legends") }}
                </nuxt-link>
              </li>
            </ul>
          </div>
        </li>

        <!--        COMPETITION Dropdown-->
        <li class="nav-link">
          <button
            id="competitionNavbar"
            data-dropdown-toggle="dropdownCompetitionNavbar"
            data-dropdown-trigger="hover"
            class="nav-link inline-flex items-center uppercase"
            type="button"
          >
            {{ $t("header.navCompetition.competition") }}
            <drop-down-icon class="ml-1" width="8" height="8" />
          </button>
          <!-- Dropdown menu -->
          <div
            id="dropdownCompetitionNavbar"
            class="z-30 hidden rounded-lg shadow text-left bg-gray-900 border border-gray-700 py-2 w-[262px]"
          >
            <ul class="text-sm text-gray-400" aria-labelledby="competitionNavbar">
              <li class="subitem-dropdown-competition">
                <nuxt-link :to="localePath('region-Matches')" class="subitem-link-navbar">
                  {{ $t("header.navCompetition.matches") }}
                </nuxt-link>
              </li>

              <li class="subitem-dropdown-competition">
                <nuxt-link :to="localePath('region-Tables')" class="subitem-link-navbar">
                  {{ $t("header.navCompetition.tables") }}
                </nuxt-link>
              </li>
              <li class="subitem-dropdown-competition">
                <nuxt-link :to="localePath('region-Stats')" class="subitem-link-navbar" no-prefetch>
                  {{ $t("header.navCompetition.stats") }}
                </nuxt-link>
              </li>
            </ul>
          </div>
        </li>

        <li v-if="isSpain || isAmericas" class="nav-link">
          <nuxt-link no-prefetch :to="localePath('region-Draft')">
            {{ $t("header.navCompetition.draft") }}
          </nuxt-link>
        </li>
        <li class="nav-link" v-if="isKings && isWorldCup">
          <nuxt-link no-prefetch :to="localePath('region-Tournament')">
            {{ $t("header.navCompetition.tournament") }}
          </nuxt-link>
        </li>
        <li class="nav-link" v-if="isKings && !isAmericas && !isWorldCup">
          <nuxt-link to="https://kingsleague.hro.gg/" external target="_blank" no-prefetch>
            <span class="flex items-center gap-1">
              <!--              F icon-->
              <svg
                width="auto"
                height="12"
                viewBox="0 0 345 341"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M122.9 226.4H213.7L262.5 141.9H122.9V84.5H295.6L344.4 0H0.799805V302.5L115.3 340.1L122.9 337.6V226.4Z"
                  fill="currentColor"
                />
              </svg>
              {{ $t("header.navCompetition.fantasy") }}
            </span>
          </nuxt-link>
        </li>
      </ul>
      <ul
        class="flex lg:space-x-6 text-white text-sm uppercase"
        v-else-if="isItaly || (isQueens && isAmericas)"
      >
        <li class="nav-link">
          <nuxt-link no-prefetch :to="localePath('region')">
            {{ $t("header.navCompetition.draft") }}
          </nuxt-link>
        </li>
      </ul>
    </nav>
    <div class="flex space-x-2">
      <buttons-navbar
        v-if="isKings || (isQueens && !isAmericas)"
        live
        tickets
        :store="!isItaly"
        :store-url="region?.storeUrl"
        :live-url="liveButtonUrl"
        :twitch-is-live="twitchIsLive"
      ></buttons-navbar>
    </div>
  </div>
</template>

<style scoped>
.nav-link {
  @apply text-gray-300;
}
.subitem-dropdown-competition,
.subitem-dropdown-players {
  @apply py-2 px-4 w-full hover:bg-primary hover:text-black;
}

.subitem-link-navbar:hover {
  color: black !important;
}

.subitem-link-navbar {
  text-transform: none !important;
  @apply block;
}
</style>
