<!--
// nuxt-ui/components/layout/header/Navbar.vue
-->
<script setup lang="ts">
import ContextOtherLeaguesLogo from "~/nuxt-ui/components/context-shared/ContextOtherLeaguesLogo.vue";
import NavCompetition from "~/nuxt-ui/components/layout/header/NavCompetition.vue";
import ContextLeagueLogo from "~/nuxt-ui/components/context-shared/ContextLeagueLogo.vue";
import NavRegions from "~/nuxt-ui/components/layout/header/NavRegions.vue";
import LangSwitcher from "~/nuxt-ui/components/layout/header/LangSwitcher.vue";
import ButtonsNavbar from "~/nuxt-ui/components/layout/header/ButtonsNavbar.vue";
import IconBurgerShowDropbar from "~/nuxt-ui/components/layout/header/IconBurgerShowDropbar.vue";
import DropbarCompetitionMenuMobile from "~/nuxt-ui/components/layout/header/DropbarCompetitionMenuMobile.vue";
import DropbarRegionsMenuMobile from "~/nuxt-ui/components/layout/header/DropbarRegionsMenuMobile.vue";
import ButtonRegionsMenuMobile from "~/nuxt-ui/components/layout/header/ButtonRegionsMenuMobile.vue";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import type { SocialMedia } from "~/src/region/domain/models/Region";
import { useTwitch } from "~/nuxt-ui/Shared/composables/twitch";
import { $TWITCH_INTERVAL_REFRESH } from "~/src/twitch/domain/services/twitchService";

type Props = {
  toolbarVisible: boolean;
};

withDefaults(defineProps<Props>(), {
  toolbarVisible: true,
});

const { region } = useCompetitionStore();
const { contextSiteTitle, webContext, isKings, isQueens } = useWebContext();
const { isSpain, isWorldCup, isItaly, isAmericas } = useRegions();
const { getTwitchLiveInfo } = useTwitch();
const { locale } = useI18n();

const showNavCompetitionMobile = ref<boolean>(false);
const showNavRegionsMobile = ref<boolean>(false);
const twitchIsLive = ref<boolean>(false);
const intervalId = ref<NodeJS.Timeout>();

const socialMedia: SocialMedia[] = region?.social[webContext] || [];
const liveButtonUrl: string | undefined =
  isWorldCup && locale.value === "es"
    ? "https://www.twitch.tv/kingsleague"
    : socialMedia.find(s => s.name === "twitch")?.url;

const updateTwitchStatus = async (): Promise<void> => {
  const twitchInfo = await getTwitchLiveInfo();
  if (twitchInfo) {
    twitchIsLive.value = twitchInfo.live;
  }
};

const showRegionsMenu = () => {
  const theBody: HTMLBodyElement | null = document.querySelector("body");
  showNavRegionsMobile.value = !showNavRegionsMobile.value;
  showNavCompetitionMobile.value = false;
  if (theBody) {
    showNavRegionsMobile.value
      ? theBody.classList.add("overflow-hidden")
      : theBody.classList.remove("overflow-hidden");
  }
};

const newRegionSelected = () => {
  showNavRegionsMobile.value = false;
  const theBody: HTMLBodyElement | null = document.querySelector("body");
  if (theBody) {
    theBody.classList.remove("overflow-hidden");
  }
};

const showCompetitionMenu = () => {
  const theBody: HTMLBodyElement | null = document.querySelector("body");
  showNavCompetitionMobile.value = !showNavCompetitionMobile.value;
  showNavRegionsMobile.value = false;
  if (theBody) {
    showNavCompetitionMobile.value
      ? theBody.classList.add("overflow-hidden")
      : theBody.classList.remove("overflow-hidden");
  }
};

const handleBodyClass = () => {
  const theBody: HTMLBodyElement | null = document.querySelector("body");
  if (window.innerWidth > 1199) {
    showNavCompetitionMobile.value = false;
    showNavRegionsMobile.value = false;
    if (theBody) {
      theBody.classList.remove("overflow-hidden");
    }
  }
};

const clearMatchInterval = () => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
    intervalId.value = undefined;
  }
};

onMounted(async () => {
  window.addEventListener("resize", handleBodyClass);
  await nextTick();

  if ((isKings && !isItaly) || (isQueens && !isAmericas)) {
    await updateTwitchStatus();
    intervalId.value = setInterval(async function () {
      await updateTwitchStatus();
    }, $TWITCH_INTERVAL_REFRESH);
  }
});

onUnmounted(() => {
  window.removeEventListener("resize", handleBodyClass);
  clearMatchInterval();
});
</script>

<template>
  <h1 v-if="region" class="sr-only">{{ contextSiteTitle + " - " + region.name }}</h1>
  <div class="nav-regions">
    <div class="w-[152px]"></div>

    <nav-regions></nav-regions>

    <div class="ml-auto pr-5">
      <lang-switcher button-id="dropdownLangDesktop" dropdown-toggle="dropdownDesktop"></lang-switcher>
    </div>

    <context-other-leagues-logo></context-other-leagues-logo>
  </div>

  <div class="nav-competition">
    <div class="logo-wrapper" :class="!toolbarVisible ? 'h-full pb-4 lg:h-[91px]' : 'h-full lg:h-[120px]'">
      <context-league-logo
        border-shape
        height="h-full"
        :width="!toolbarVisible ? 'w-[38px] lg:w-[74px]' : 'w-[32px] lg:w-[88px]'"
      />
    </div>

    <nav-competition :twitch-is-live="twitchIsLive"></nav-competition>

    <button-regions-menu-mobile
      :actual-region="region?.name"
      :show-nav-regions-mobile="showNavRegionsMobile"
      @show-regions-menu="showRegionsMenu"
    />

    <icon-burger-show-dropbar
      :show-nav-competition-mobile="showNavCompetitionMobile"
      @show-nav-mobile="showCompetitionMenu"
    />
  </div>

  <div class="w-full flex px-4 py-2 items-center justify-start gap-x-2 bg-black lg:hidden">
    <buttons-navbar
      v-if="isKings || (isQueens && !isAmericas)"
      full
      live
      :store="!isItaly"
      tickets
      :store-url="region?.storeUrl"
      :live-url="liveButtonUrl"
      :twitch-is-live="twitchIsLive"
    ></buttons-navbar>
  </div>

  <dropbar-competition-menu-mobile v-model:show-nav-competition-mobile="showNavCompetitionMobile" />

  <dropbar-regions-menu-mobile
    :show-nav-regions-mobile="showNavRegionsMobile"
    @region-selected="newRegionSelected"
  />
</template>

<style scoped>
.nav-regions {
  @apply w-full pr-6 py-2 max-h-14 justify-start items-center bg-white hidden lg:flex;
}

.nav-competition {
  @apply w-full bg-white lg:bg-black relative z-20 lg:z-auto lg:static border-b-primary border-b px-4 py-2;
  @apply flex justify-start items-center h-[72px] lg:max-h-16 lg:pl-0 lg:py-3.5 lg:pr-6 lg:border-b-0;
}

.logo-wrapper {
  @apply w-auto py-0 flex flex-col items-center justify-center lg:self-end lg:min-w-[152px];
  @apply lg:pt-2 lg:pb-3.5 transition-[height] ease-in-out duration-200;
}
</style>
