<!--
// nuxt-ui/components/layout/footer/GridTeamsShields.vue
-->
<script setup lang="ts">
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { setAliasUrl, slugify } from "~/src/Shared/utils";
import { orderTeamsAlphabetically } from "~/src/team/domain/services/teamService";
import nuxtLink from "#app/components/nuxt-link";

const { currentSeasonTeams } = useCompetitionStore();
const { regionParam, isWorldCup, isRegionHome } = useRegions();
const localePath = useLocalePath();

const activeTeams = currentSeasonTeams.filter(t => !t.isPlaceholder);
const teams = orderTeamsAlphabetically(activeTeams);
</script>

<template>
  <section class="py-5 px-4 md:px-0 border-b border-b-primary">
    <div :class="{ 'wrapper-slide hide-scrollbar': isWorldCup }">
      <div
        :class="isWorldCup ? 'slide-teams' : 'grid-shields-container kql-w-container'"
        v-if="currentSeasonTeams"
        v-for="i in isWorldCup ? 2 : 1"
        :key="i"
      >
        <div v-for="team in teams" :key="team.id" class="team-shield">
          <component
            :is="nuxtLink"
            :to="
              localePath({
                name: 'region-teams-team-teamName',
                params: { region: regionParam, team: team.id, teamName: slugify(team.name) },
              })
            "
            class="flex flex-col items-center justify-center"
            :no-prefetch="nuxtLink && isRegionHome ? true : undefined"
          >
            <div class="logo-container">
              <NuxtImg
                v-if="team.logo?.url"
                :src="setAliasUrl(team.logo.url)"
                :alt="team.name"
                class="max-w-full max-h-full"
                height="56"
                width="56"
                loading="lazy"
              />
            </div>
            <p class="team-name">{{ team.shortName }}</p>
          </component>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.grid-shields-container {
  @apply grid grid-cols-4 sm:grid-cols-6 md:flex justify-center items-center gap-4 md:gap-6 lg:gap-9;
}

.logo-container {
  @apply w-14 h-14 flex flex-col justify-center items-center;
}

.team-name {
  @apply text-center md:flex justify-center items-center uppercase text-xs text-gray-400 leading-[18px] mt-1 hidden;
}

.wrapper-slide {
  @apply max-w-[1440px] overflow-x-scroll mx-auto flex gap-4 md:gap-6 lg:gap-9;
}

.slide-teams {
  @apply flex justify-start items-center gap-4 md:gap-6 lg:gap-9;
  transform: translateX(0%);
  animation: scrollLeft 65s linear infinite;
  animation-direction: inherit;
  will-change: transform;
}

.wrapper-slide:hover .slide-teams {
  animation-play-state: paused;
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0%) translateZ(0);
  }
  100% {
    transform: translateX(-100%) translateZ(0);
  }
}
</style>
