<!--
// nuxt-ui/components/layout/TheFooter.vue
-->
<script setup lang="ts">
import SocialIcons from "~/nuxt-ui/components/Shared/SocialIcons.vue";
import MainGradientDivider from "~/nuxt-ui/components/context-shared/MainGradientDivider.vue";
import BannerCompetitionName from "~/nuxt-ui/components/context-shared/BannerCompetitionName.vue";
import GridTeamsShields from "~/nuxt-ui/components/layout/footer/GridTeamsShields.vue";
import ContextOtherLeaguesLogo from "~/nuxt-ui/components/context-shared/ContextOtherLeaguesLogo.vue";
import ContextLogoLeagueFooter from "~/nuxt-ui/components/context-shared/ContextLogoLeagueFooter.vue";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";

const { region } = useCompetitionStore();
const { isKings, isQueens, webContext, contextSiteTitle } = useWebContext();
const { isSpain, isWorldCup, isItaly, isAmericas } = useRegions();
const localePath = useLocalePath();

const regionSocial = region?.social[webContext];
const socialProfiles = {
  twitter: regionSocial ? regionSocial?.find(s => s.name === "twitter")?.url : undefined,
  discord: regionSocial ? regionSocial.find(s => s.name === "discord")?.url : undefined,
  instagram: regionSocial ? regionSocial.find(s => s.name === "instagram")?.url : undefined,
  twitch: regionSocial ? regionSocial.find(s => s.name === "twitch")?.url : undefined,
  tiktok: regionSocial ? regionSocial.find(s => s.name === "tiktok")?.url : undefined,
  youtube: regionSocial ? regionSocial.find(s => s.name === "youtube")?.url : undefined,
};

const actualYear = new Date().getFullYear();
</script>

<template>
  <footer>
    <main-gradient-divider height="h-2.5"></main-gradient-divider>
    <banner-competition-name></banner-competition-name>
    <grid-teams-shields v-if="(!isItaly && isKings) || (isQueens && !isAmericas)"></grid-teams-shields>
    <div class="kql-w-container py-10 px-4 md:px-6">
      <div class="container-items-footer">
        <div>
          <div class="flex flex-col items-start justify-between gap-y-5">
            <context-logo-league-footer></context-logo-league-footer>
            <social-icons :social-profiles="socialProfiles"></social-icons>
          </div>
        </div>
        <div class="mt-8 md:mt-0 flex items-start justify-start md:justify-end">
          <ul v-if="(!isItaly && isKings) || (isQueens && !isAmericas)" class="nav-footer-es">
            <li>
              <nuxt-link :to="localePath('region-Teams')">{{ $t("header.navCompetition.teams") }}</nuxt-link>
            </li>
            <li>
              <nuxt-link no-prefetch :to="localePath('region-PlayersVariable')">{{
                $t("header.navCompetition.12-13")
              }}</nuxt-link>
            </li>
            <li v-if="isKings && isSpain">
              <nuxt-link no-prefetch :to="localePath('region-PlayersLegend')">{{
                $t("header.navCompetition.legends")
              }}</nuxt-link>
            </li>
            <li>
              <nuxt-link no-prefetch :to="localePath('region-PlayersDraft')">{{
                $t("header.navCompetition.playersDraft")
              }}</nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath('region-Matches')">{{
                $t("header.navCompetition.matches")
              }}</nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath('region-Tables')">{{
                $t("header.navCompetition.tables")
              }}</nuxt-link>
            </li>
            <li>
              <nuxt-link no-prefetch :to="localePath('region-Stats')">{{
                $t("header.navCompetition.stats")
              }}</nuxt-link>
            </li>
            <li v-if="(isKings && !isWorldCup) || isQueens">
              <nuxt-link no-prefetch :to="localePath('region-Draft')">{{
                $t("header.navCompetition.draft")
              }}</nuxt-link>
            </li>
            <li v-else-if="isWorldCup">
              <nuxt-link no-prefetch :to="localePath('region-JoinTheDraft')">{{
                $t("header.navCompetition.draft")
              }}</nuxt-link>
            </li>
            <li>
              <nuxt-link no-prefetch :to="localePath('region-News')">{{ $t("footer.nav.news") }}</nuxt-link>
            </li>
            <li v-if="isKings && isWorldCup">
              <nuxt-link no-prefetch :to="localePath('region-TierList')">{{
                $t("header.navCompetition.tierList")
              }}</nuxt-link>
            </li>
            <li>
              <nuxt-link no-prefetch :to="localePath('region-Regulation')">{{
                $t("regulation.title")
              }}</nuxt-link>
            </li>
            <li>
              <nuxt-link no-prefetch :to="localePath('region-ContactUs')">{{
                $t("footer.nav.contact")
              }}</nuxt-link>
            </li>
            <li>
              <a href="https://kosmos.teamtailor.com/" target="_blank">{{ $t("footer.nav.careers") }}</a>
            </li>
            <li v-if="isSpain">
              <nuxt-link no-prefetch :to="localePath('region-Tickets')">{{ $t("header.tickets") }}</nuxt-link>
            </li>
          </ul>

          <ul v-if="isItaly || (isQueens && isAmericas)" class="nav-footer-it">
            <li>
              <a href="https://kosmos.teamtailor.com/" target="_blank">{{ $t("footer.nav.careers") }}</a>
            </li>
          </ul>
        </div>
        <div class="pt-12 md:pt-0 flex justify-start md:justify-end">
          <context-other-leagues-logo height="h-[66px]"></context-other-leagues-logo>
        </div>
      </div>
      <div class="pt-8 text-xs text-gray-500">
        <p>© {{ actualYear }} {{ contextSiteTitle }}. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.container-items-footer {
  @apply pb-12 border-b border-black gap-x-10 lg:gap-x-24 grid grid-cols-1 items-start md:grid-cols-[384px_auto_114px];
}

.nav-footer-es {
  @apply text-sm md:columns-3 gap-x-8 md:max-h-full max-w-max;
}

.nav-footer-it {
  @apply text-sm w-full md:pl-28;
}

/*.nav-footer-wc {
  @apply text-sm md:columns-2 gap-x-8 md:max-h-full max-w-max;
}*/

.nav-footer-am li,
.nav-footer-es li {
  @apply lg:w-32 xl:w-44 mb-4;
}
</style>
